<template>
    <el-container>
        <el-main>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">供片系统管理方</el-breadcrumb-item>
                <el-breadcrumb-item><a>运行日报</a></el-breadcrumb-item>
            </el-breadcrumb>
            <div id="rili">
                <el-calendar v-model="value">
                </el-calendar>
            </div>
            <div id="shang">
                <div class="szt" id="yue_shebei">
                    <div id="container" style="height: 100%"></div>
                </div>
                <div class="szt" id="yue_xinyp">
                    <div id="container2" style="height: 100%"></div>
                </div>
                <div class="szt" id="DKDM">
                    <h6>DKDM过期预警</h6>
                    <template>
                        <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
                            <li v-for="i in count" class="infinite-list-item">{{ i }}</li>
                        </ul>
                    </template>
                </div>
            </div>
            <div id="xia">
                <div class="szt" id="yue_miyao">
                    <div id="container3" style="height: 100%"></div>
                </div>
                <div class="szt" id="yue_jiuyp">
                    <div id="container4" style="height: 100%"></div>
                </div>
                <div class="szt" id="DKDM7">
                    <h6>DKDM7日过期报警</h6>
                    <template>
                        <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
                            <li v-for="i in count" class="infinite-list-item">{{ i }}</li>
                        </ul>
                    </template>
                </div>
            </div>
        </el-main>

    </el-container>
</template>



<script>
    export default {
        name: "Menu4.Report",
        data () {
            return {
                count: 0
            }
        },
        methods: {
            load () {
                this.count += 2
            }
        }
    }
</script>

<style scoped>

</style>